// import './css/fullpage.css';
// import './css/mapbox-gl.css';
// import 'swiper/swiper-bundle.css';
// import './css/tw-base.css';
// import './css/typography.css';
// import './css/tw-other.css';
// import './css/fullpage-custom.css';
// import './css/layout.css';
// import './css/swiper.css';

import './css/main.css';

import Swiper, { Navigation, Pagination } from 'swiper';

import mapboxgl from 'mapbox-gl';


import scrollHorizontally from './js/fullpage.scrollHorizontally.min.js';
import dragAndMove from './js/fullpage.dragAndMove.min.js';
import scrollOverflow from './js/fullpage.scrolloverflow.min.js';
import fullpage from 'fullpage.js/dist/fullpage.extensions.min';
// var Granim = require('granim');


// import BackgroundCheck from './js/background-check.min.js';
// import imagesLoaded from 'imagesloaded';


// BackgroundCheck.init({
//     targets: '.backgroundcheck-text',
//     images: '.backgroundcheck-images'
// });

// imagesLoaded( '#fullpage', function() {
   
//     console.log("REFRESH");
// });





var eventmap = document.getElementById("eventmap");
if (typeof(eventmap) != 'undefined' && eventmap != null ) {

    var lng = eventmap.getAttribute("data-lng");
    var lat = eventmap.getAttribute("data-lat");

    var localmarkerpin = document.querySelector(".mapmarkerpin");

    mapboxgl.accessToken = 'pk.eyJ1IjoibWlsbGNvIiwiYSI6ImNrcjBzbmtpYzF1dmwycHFwajEzbHIxdGEifQ.hdV70L-T53bpdn6cbcsCLg';
    const map = new mapboxgl.Map({
        container: 'eventmap', // container ID
        style: 'mapbox://styles/millco/ckr0ss4qn282k17mxeuc4ev38', // style URL
        center: [lng, lat ], // starting position [lng, lat] 
        zoom: 11.5 // starting zoom
        });

    var marker = new mapboxgl.Marker(localmarkerpin,
        {
            anchor: 'center',
        })
    .setLngLat([lng, lat])
    .addTo(map);
} else false;


var studiosmap = document.getElementById("studiosmap");
if (typeof(studiosmap) != 'undefined' && studiosmap != null ) {

    var lng = studiosmap.getAttribute("data-lng");
    var lat = studiosmap.getAttribute("data-lat");


    mapboxgl.accessToken = 'pk.eyJ1IjoibWlsbGNvIiwiYSI6ImNrcjBzbmtpYzF1dmwycHFwajEzbHIxdGEifQ.hdV70L-T53bpdn6cbcsCLg';
    const buildingmap = new mapboxgl.Map({
        container: 'studiosmap', // container ID
        style: 'mapbox://styles/millco/ckr0ss4qn282k17mxeuc4ev38', // style URL
        center: [lng, lat ], // starting position [lng, lat] 
        zoom: 11.5 // starting zoom
        });


    var g;
    var buildingsmarker = document.getElementsByClassName("buildings-map-marker");
    for (g = 0; g < buildingsmarker.length; g++) {

        var buildinglng = buildingsmarker[g].getAttribute("data-lng");
        var buildinglat = buildingsmarker[g].getAttribute("data-lat");

        var marker = new mapboxgl.Marker(buildingsmarker[g],
            {
                anchor: 'center',
            })
        .setLngLat([buildinglng, buildinglat])
        .addTo(buildingmap);
    };

    
} else false;





var fullpagediv = document.getElementsByClassName("fullpage");

if (fullpagediv.length > 0) {

    var tooltiparray = [];

    var i;
    var thesections = document.getElementsByClassName("section");
    for (i=0; i < thesections.length; i++) {
        var singletooltip = thesections[i].getAttribute("data-tooltips");

        tooltiparray.push(singletooltip);

    };
    
    var thefullpage = new fullpage('#fullpage', {

        licenseKey: 'A6D86A60-95084427-A27A5CE6-C3BE9625',
        controlArrows: true,
        navigation: true,
        navigationPosition: 'right',
        navigationTooltips: tooltiparray,
        slidesNavigation: true,
        dragAndMoveKey: 'bW9kZXJuYWN0aXZpdHkuY29tXzlJalpISmhaMEZ1WkUxdmRtVT04V0k=',
        dragAndMove: true,
        scrollHorizontallyKey: 'bW9kZXJuYWN0aXZpdHkuY29tXzA1ZGMyTnliMnhzU0c5eWFYcHZiblJoYkd4NU1JNA==',
        scrollHorizontally: true,

    });

    console.log(tooltiparray);

} else false;

// var granimInstance = new Granim({
//     element: '#granim-canvas',
//     name: 'granim',
//     direction: 'top-bottom',
//     opacity: [1, 1],
//     states : {
//         "default-state": {
//             gradients: [
//                  [
//                      { color: 'hsla(3, 58%, 52%, 1)', pos: .2 },
//                      { color: 'hsla(26, 48%, 79%, 1)', pos: .3 },
//                      { color: 'hsla(254, 100%, 50%, 1)', pos: 1 }
//                  ], [
//                      { color: 'hsla(247, 100%, 47%, 1)', pos: 0 },
//                      { color: 'hsla(180, 67%, 26%, 1)', pos: .7 },
//                      { color: 'hsla(3, 58%, 52%, 1)', pos: .9 }
//                  ],
//              ]
//         }
//     }
//  });



// ------------------------------  Swiper ------------------------------

Swiper.use([Navigation, Pagination]); 

var swipercont = document.getElementById("swiper-gallery");
if (typeof(swipercont) != 'undefined' && swipercont != null ) {
    var swiperlength = document.getElementById("swiper-gallery").getAttribute("data-loopnum");

    var swiper = new Swiper('.swiper-container', {
    loop: true,
    init: false,
    preloadImages: false,
    slidesPerView: 1,
    watchSlidesVisibility: true,
    loopedSlides: swiperlength,
        centeredSlides: true,
        spaceBetween: 64,
    // If we need pagination
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    
    });

    swiper.on('afterInit', function () {
        var currentslide = swiper.realIndex;
        var caption = swiper.slides[currentslide].getAttribute("data-caption");

        document.getElementById("gallery-caption").innerHTML = caption;

        document.getElementById("gallery-fullscreen-caption").innerHTML = caption;

        // console.log("Swiper init");
      });
    
    swiper.init();


    swiper.on('slideChange', function () {
        var currentslide = swiper.realIndex;
        var caption = swiper.slides[currentslide].getAttribute("data-caption");
        
        document.getElementById("gallery-fullscreen-caption").innerHTML = caption;
        document.getElementById("gallery-caption").innerHTML = caption;
      });

      


} else false;




// ------------------------------  Main Menu ------------------------------



document.getElementById("menu-button").addEventListener('click', (e) => {

    const menu = document.getElementById("menu");
    const button = document.getElementById("menu-button");
    menu.classList.toggle("translate-x-[100vw]");

    if (button.getAttribute("data-menu-status") == "closed") {
        button.innerHTML = "CLOSE";
        button.setAttribute("data-menu-status", "open");
    } else {
        button.innerHTML = "MENU";
        button.setAttribute("data-menu-status", "closed");
    };

});

// document.getElementById("menu-button-mob").addEventListener('click', (e) => {

//     const menu = document.getElementById("menu");
//     const buttonMob = document.getElementById("menu-button-mob");
//     menu.classList.toggle("translate-x-[100vw]");

//         if (buttonMob.getAttribute("data-menu-status") == "closed") {
//         buttonMob.innerHTML = "CLOSE";
//         buttonMob.setAttribute("data-menu-status", "open");
//     } else {
//         buttonMob.innerHTML = "MENU";
//         buttonMob.setAttribute("data-menu-status", "closed");
//     };

// });




// ------------------------------  tile hover ------------------------------

// const closest = e.closest(".textTile");
// closest.style.background = 'yellow';

// var hoverTrigger = document.getElementByClassName('textTile');

//  function handleModalOpen() {
//     modal.classList.remove("is-hidden");
//   }

//  function handleModalClick(evt) {
//     if (!evt.target.closest(".modal-inner")) {
//       handleModalClose();
//     }
//   }

var bgcolour = "bg-red-400";

function tileHover(postID) {
    var outdiv = document.getElementById("event-div-" + postID);
    outdiv.querySelector(".divHover1").classList.add(bgcolour);
    outdiv.querySelector(".divHover2").classList.add(bgcolour);
    outdiv.querySelector(".divHover3").classList.add(bgcolour);
    outdiv.querySelector(".divHover4").classList.add(bgcolour);
}

function tileHoverOut(postID) {
    var outdiv = document.getElementById("event-div-" + postID);
    outdiv.querySelector(".divHover1").classList.remove(bgcolour);
    outdiv.querySelector(".divHover2").classList.remove(bgcolour);
    outdiv.querySelector(".divHover3").classList.remove(bgcolour);
    outdiv.querySelector(".divHover4").classList.remove(bgcolour);
}

window.tileHover = tileHover;
window.tileHoverOut = tileHoverOut;
